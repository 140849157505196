import React from 'react';
import ReactPlayer from 'react-player/lazy';

const Player = (props) => {

    return (
        <React.Fragment>
            <ReactPlayer
                frameborder="0"
                webkitallowfullscreen mozallowfullscreen allowfullscreen
                url={props.data.videoId}
                controls={true}
                muted={false}
                playsinline={true}
                playing={props.data.isOpen}
                width={props.data.width} height={props.data.height}
                className={props?.data?.className ? props.data.className : ""}
            />
            {(props.data.videoId && props.data.isOpen) &&
                <button
                    type="button"
                    className="play-btn icon-close-btn"
                    style={{ width: 50, height: 50 }}
                    onClick={() => { props.data.stopPlay(false) }}
                >
                    <i className="icon-close"></i>
                </button>
            }
        </React.Fragment>
    )
};

export default Player