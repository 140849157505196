import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useLocation } from "@reach/router";
import './Breadcrumb.scss';

const DynamicBreadcrumb = (props) => {
  const location = useLocation();
  const pathParts = location.pathname.split("/").filter((part) => part);

  const convertToReadableText = (inputText) => {
    const words = inputText.split('-');    
    const result = words.map((word, index) => {
        if (index === 0) { // Capitalize the first word
            return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
            return word.toLowerCase();
        }
    }).join(' ');    
    return result;
  };

  return (
    <Breadcrumb>
      <Breadcrumb.Item href="/">
        Home
      </Breadcrumb.Item>
      {pathParts.map((part, index) => (
        <Breadcrumb.Item
          key={index}
          active={index === pathParts.length - 1}
          href={`/${pathParts.slice(0, index + 1).join("/")}`}
        >
            { (props?.label && index === pathParts.length - 1) ? 
                props?.label : convertToReadableText(part) 
            }
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default DynamicBreadcrumb;
