import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const TextAreaField = ({name, rows, placeholder, required, handlechange, fieldClass, mainClass, label, grpmd}) => (
    <div className={"msg "+mainClass}>
        <Form.Group as={Col} md={grpmd} controlId="validation{name}">
            { label ? <Form.Label>{label}{required ? '*' : ''}</Form.Label>: ''}
            <Form.Control
                className={fieldClass}
                name={name}
                required={required}
                as="textarea"
                rows={rows}
                style={{ height: "auto"}}
                onChange={handlechange}
                placeholder={placeholder} 
            />
        </Form.Group>
    </div>
);

export default TextAreaField;