import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Link } from "gatsby";
import './Notification.scss';
import moment from "moment";
import ReapitView from "../ReapitView";
import CountDown from "../Countdown/CountDown";

const Notification = (props) => {
    const [TobBarShow, setTobBar] = useState(false);

    const TopBarClose = () => {
        setTobBar(!TobBarShow);
    };
    useEffect(() => {
        if(props.openHouse) {
            setTobBar(true)
        }
    }, [])
    useEffect(() => {
        document.body.classList.toggle('notifaction-off', TobBarShow);
    },[TobBarShow]);

    var newTime = "";
    const now = new Date().getTime();
    const distance = newTime - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));

    let openHours = "";
    let start = -1;
    let end = 1;
    if(props.openHours && props.openHours.length > 0){
        props.openHours.map((open)=>{
            if(open[moment().format("YYYY-MM-DD")]){
                openHours = open[moment().format("YYYY-MM-DD")]
            }
        })
        if(openHours){
            start = moment().diff(moment(openHours.start_date))
            end = moment().diff(moment(openHours.end_date))
            newTime = new Date(moment(openHours.end_date)); 
        }
  
    }
    return (
        <React.Fragment>
            {props.openHouse && start >= 0 && end < 0 && openHours != "" &&
            <div className={TobBarShow? "topbar d-block": "topbar"}>
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className="topbar-block">
                                <p>   <strong>Only <CountDown defineTime={newTime} textOnly={true}/> remaining of our open house</strong> Reserve your spot for our next open house so you don’t miss out.</p>
                                <ReapitView
                                    crmId={props?.property?.crm_id}
                                    dangerouslySetInnerHTML={{ 
                                        __html: `
                                            <a href="javascript:;" class="btn reapit-viewing-btn" property.id=${props?.property?.crm_id} message.appointment.title="Viewing Requested">
                                                get in touch
                                            </a>
                                        `
                                    }}
                                />
                                <i className="icon-close-modal" onClick={TopBarClose}></i>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            }
        </React.Fragment>
    )
}
export default Notification;
