import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import $ from "jquery";

export default class ReapitView extends React.Component {
    state = { isEventbriteLoaded: false };

    /** @type {string} */
    elementId = uuid();

    /** @type {string} */
    get scriptId() {
        return `${this.elementId}`;
    }


    /** @returns {Promise<{ createWidget: Function }>} */
    fetchEBWidgets = () => new Promise((resolve, reject) => {

        const { ebScriptPath } = this.props;
        // If script not exist append new 
        if ($('script[src="'+ebScriptPath+'"').length == 0) {
            const script = document.createElement('script');
            script.id = this.scriptId;
            script.async = true;
            script.src = ebScriptPath;
            script.addEventListener('load', () => resolve(true));

            /** @param {Error} e */
            const handleErr = e => {
                console.error(`Failed to load Eventbrite script from ${ebScriptPath}`);
                reject(e);
            };
            script.addEventListener('error', handleErr);
            script.addEventListener('abort', handleErr);

            document.head.appendChild(script);
        }
    });

    /** @returns {Promise<void>} */
    async componentWillMount() {
        try {
            await this.fetchEBWidgets();
            this.setState({ isEventbriteLoaded: true });
        } catch (e) {
            this.setState({ isEventbriteLoaded: false })
        }
    }

    componentWillUnmount() {
        const script = document.getElementById(this.scriptId);
        if (script) {
            script.remove();
        }
    }

    render() {
        const { children, className, component: Component, componentProps, type, dangerouslySetInnerHTML } = this.props;
        return (
            <div
                className={className}
                {...componentProps}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                onClick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'Book a Viewing',
                        'formType': 'form-property-valuation',
                        'formId': 'form-property-valuation',
                        'formName': 'Book a Viewing',
                        'formLabel': 'Book a Viewing',
                    });
                }}
            >
                
            </div>
        )
    }

    static propTypes = {
        className: PropTypes.string,
        ebScriptPath: PropTypes.string,
        isModal: PropTypes.bool,
        onOrderComplete: PropTypes.func,
        html: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        component: PropTypes.node,
        componentProps: PropTypes.shape({}),
    };

    static defaultProps = {
        className: '',
        ebScriptPath: 'https://digital.reapit.net/tlc/RDAPlugin?ApiKey=d1a283b7a5f7c60e054f29d036d94d9e&version=0.2',
        isModal: true,
        onOrderComplete: () => {},
        componentProps: {},
    };
}