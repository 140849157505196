import { useStaticQuery, graphql } from "gatsby";
import Link from './link';

export const GetURL = (id, name) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                allMenus(where: {Publish: true}) {
                    _id
                    URL
                    Main_Parent {
                        URL
                    }
                    Sub_Parent {
                        URL
                    }
                }
            }
        }
    `);
    let allMenus = data.glstrapi.allMenus;
	let PageURL = allMenus.filter(c => c._id === id)[0];
    let URL;
    if (PageURL) {
     	URL = PageURL.URL
        if(PageURL.URL.includes("http")) {
            URL = PageURL.URL
        } else if((PageURL.Main_Parent != null) &&(PageURL.Sub_Parent == null)) {
        	URL = PageURL.Main_Parent.URL + '/' +URL
      	} else if((PageURL.Main_Parent != null) &&(PageURL.Sub_Parent != null)) {
        	URL = PageURL.Main_Parent.URL + '/' + PageURL.Sub_Parent.URL + '/' +URL
      	} 
    }
    if(URL && URL.endsWith("/")) {
        if(URL.startsWith("http")) {
            return URL;
        }
        return "/"+URL;
    }
    if(URL && URL.startsWith("http")) {
        return URL;
    }
	return "/"+URL+"/";
}

export const getVideoId = (url) => {
    if (!url)
        return;

    if(url.split("v=").length > 1) {
        var video_id = url.split('v=')[1];
        var ampersandPosition = video_id.indexOf('&');
        if(ampersandPosition != -1) {
            video_id = video_id.substring(0, ampersandPosition);
        }
        return video_id
    } else {
        return url.split("youtu.be/")[1]
    }
}

export const numberFormat = num => {
    if (!num) return 0
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format
    return new Intl.NumberFormat("en-US", {}).format(num)
}

/**
 * parseInt
 */
export const filterNumber = str => {
    if (!str) return 0
    str = str.toString()
    return parseInt(str.replace(/[^\d.]/g, ""), 10)
}

/**
 * Interest rate, periods, Present value of loan, Future value of loan, Calculated at start of each period
 */
export const pmt = (rate, periods, present, future = 0, type = 1) => {
    if (isNaN(rate) || isNaN(periods) || !periods || !rate) {
        return 0
    }

    if (rate === 0) {
        return -((present + future) / periods)
    } else {
        var term = Math.pow(1 + rate, periods)
        if (type === 1) {
            return -(
            ((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)) /
            (1 + rate)
            )
        } else {
            return -((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term))
        }
    }
}


export const removePlus = (number) => {
    if(number.startsWith("+")) {
        return number.substring(1).split(" ").join("")
    } 

    return number.split(" ").join("")
}

const getPidFromUrl = (url = '') => {
    if (!url) {
        url = typeof window !== 'undefined' ? window.location.href : ''
    }

    let urlObj = url.split('-');
    return urlObj[urlObj.length-1].replace(/[/#!]/g,'').split("?")[0];
}

export {
    Link,
    getPidFromUrl
}


export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};