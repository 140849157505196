import React from "react";
import { Modal } from "react-bootstrap";
import Player from "./Player";

const PlayVideo = (props)  => {

	if(props.isOpen) {
        const url = typeof window !== 'undefined' ? window.location.href : '';
        const event = props.event ? `${props.event} - ${url}` : url;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'Video Play Btn',
        'formType': event,
        'formId': 'Video Play',
        'formName': 'Video',
        'formLabel': 'Video'
        }); 
	}
    return (
        <React.Fragment>
            {props?.className ?
                <Player data={props}/>
            :
                <Modal
                    fullscreen={props.fullscreen}
                    show={props.isOpen}
                    style={{'overflow-y':'hidden'}}
                >
                    <Player data={props}/>
                </Modal>
            }
        </React.Fragment>
    )
};

export default PlayVideo;