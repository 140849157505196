import React from "react";
import { useTimer } from 'react-timer-hook';
import "./CountDown.scss";
function MyTimer({ expiryTimestamp, textOnly }) {
    const {
        seconds,
        minutes,
        hours,
        days,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });
    let dateNewDays = ("" + days).split("");
    let dateNewMin = ("" + minutes).split("");
    let dateNewHrs = ("" + hours).split("");
    let dateNewSec = ("" + seconds).split(""); 
    if (textOnly) {
        return (
            <>
                {days >= 1 ?
                    <span className="counter-box">{hours} {hours == 1 ? "day" : "days"}</span>
                    : hours >= 1 ?
                        <span className="counter-box">{hours} {hours == 1 ? "hour" : "hours"}</span>
                        :
                        <span className="counter-box">{minutes} mins</span>

                } 
            </>
        );
    } else {
        return (
            <ul>
                <li>
                    <div className="date-box">
                        {dateNewDays.map((item) => (
                            <span className="counter-box">{item}</span>
                        ))}
                    </div>
                    <span className="counter-text">days</span>
                </li>
                <li>
                    <div className="date-box">
                        {dateNewHrs.map((item) => (
                            <span className="counter-box">{item}</span>
                        ))}
                    </div>
                    <span className="counter-text">hours</span>
                </li>
                <li>
                    <div className="date-box">
                        {dateNewMin.map((item) => (
                            <span className="counter-box">{item}</span>
                        ))}
                    </div>
                    <span className="counter-text">mins</span>
                </li>
                <li>
                    <div className="date-box">
                        {dateNewSec.map((item) => (
                            <span className="counter-box">{item}</span>
                        ))}
                    </div>
                    <span className="counter-text">secs</span>
                </li>
            </ul>
        );
    }

}
const CountDown = (props) => {
    return (
        <>
            <MyTimer expiryTimestamp={props.defineTime} textOnly={props.textOnly} />
        </>
    );
}
export default CountDown;
