import React, { useState, useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import InputField from './elements/input';
import SelectField from './elements/select';
import TextAreaField from './elements/textarea';
import CheckboxField from './elements/checkbox';
import ButtonField from './elements/button';
import HtmlBox from './elements/html';
import ReCaptchaBox from './elements/recaptcha';
import { postFormData } from "./api/Api";
import moment from "moment";
import axios from "axios";
import * as qs from "query-string";
import _ from "lodash";

const to_email_id = "emily.south@tlclondon.com";

function MySimpleForm(props) {
    const [validated, setValidated] = useState(false);
    const [showerror, setShowerror] = useState(false);
    const [showthankyou, setThankyou] = useState(false);
    const [formvalues, setFormvalues] = useState("");
    const [token, setToken] = useState("");
    const myRef = React.createRef();
    const recaptchaRef = React.createRef();
    const [countryCode, setCountryCode] = useState('');
    const [showCapcha, setShowCaptch] = useState(true);

    useEffect(() => {
        axios.get('https://extreme-ip-lookup.com/json/').then(response => {
        //console.log("countryCode", response.data.countryCode);
            setCountryCode(response.data.countryCode)
        }).catch(err =>
            console.log(err)
        );
        if(props.email) {
            let el = document.querySelectorAll(".subscribe-input");
            if(el.length > 0) {
                let element = el[0];
                element.value = props.email
            }
        }
    },[]);

    const fields = ([
        {
            element: "config",
            formname: "Subscribe",
            form_type: "news_subscription",
            error_text: "Highlighted fields are required | invalid",
            success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
            email_temp_user: "newsletter_user",
            email_temp_admin: "newsletter_admin",
            email_subject_user: "News Subscription",
            email_subject_admin: "News Subscription",
            email_server_func: "contact",
            event_tracking: "contact",
            page_url: "/contact"
        },
        {
            grpmd: "12",
            label: "Name",
            placeholder: "Your name",
            name: "name",
            type: "text",
            element: "input",
            required: true,
            patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
            labelClass: "content_b-18"
        },
        {
            grpmd: "12",
            label: "Email Address:",
            placeholder: "Email Address",
            name: "email",
            type: "email",
            element: "input",
            class: "subscribe-input",
            required: true,
            patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
            labelClass: "content_b-18"
        },
        {
            grpmd: "12",
            name: "SUBMIT",
            type:"submit",
            element: "button",
            value: "Subscribe",
            class: "btn subscribe-btn btn btn-primary w-100 mb-4",
            labelClass: "content_b-18"
        },
        {
            text: 'By clicking Submit, you agree to our <a href="/terms-and-conditions/">Terms &amp; Conditions</a> and <a href="/privacy-policy/">Privacy Policy.</a>',
            element: "html",
            class: "form-txt content_r-m"
        },
        {
            element: "captcha",
            class: "py-2",
            captchaRef: recaptchaRef
        },
    ]);

    const handlechange = event => {
        // remove initial empty spaces
        event.target.value = event.target.value.trimStart()
    }

    useEffect(() => {
        if (token !== '') {
            const processFromData = async () => {
                let formData = new FormData();
                formData.append('data', JSON.stringify(formvalues));
                postFormData(formData).then(async apiRes => {
                    window.grecaptcha.reset();
                    // lets send mail
                    await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
                    // await window.fetch(`/api/form`, {
                        method: `POST`,
                        mode: "no-cors",
                        headers: {
                          'Access-Control-Allow-Origin': '*',
                          "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: qs.stringify(formvalues),
                    })
                });

                formvalues['g-recaptcha-response'] = token;

                // tracking event
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'formSubmit',
                    'formType': 'form-'+fields[0].event_tracking,
                    'formId': 'form-'+fields[0].event_tracking,
                    'formName': fields[0].formname,
                    'formLabel': fields[0].formname
                });

                setShowerror(false);
                setThankyou(true);
                setShowCaptch(false);
                setTimeout(() => {
                    setThankyou(false);
                    setShowCaptch(true);
                }, 3000);
                myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

            }
            processFromData();
        }
    }, [token]);

    const handleonVerify = token => {
        setToken(token);
    };

    const handleSubmit = event => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setShowerror(true);
            setValidated(true);
            setThankyou(false);
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        } else {
            const formsdata = (event.target);
            const json = {};
            Object.keys(formsdata).map(key => (
                json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
            ))
            json['email_temp_user'] = fields[0].email_temp_user;
            json['email_temp_admin'] = fields[0].email_temp_admin;
            json['formname'] = fields[0].formname;
            json['g-recaptcha-response'] = token;
            json["email_subject_user"] = "tlc Estate Agents - Newsletter";
            json["email_subject_admin"] = "tlclondon - Newsletter Subscription";

            if(props.email) {
                json["to_email_id"] = props.email;
            }
            if(props.bcc) {
                json["to_bcc"] = props.bcc;
            }

            json['extra'] = _.omit(json, ["formname", "name", "email", "telephone"])

            setFormvalues(json);
            recaptchaRef.current.execute();
            setValidated(false);
            // reset form
            const form = event.target
            form.reset();
        }
    };

    return (
        <div className="form-wrapper">
            <div ref={myRef} />

            {showerror && <div className="alert alert-danger mt-3 text-center">
                <p className="mb-0">{fields[0].error_text}</p>
            </div>}

            {showthankyou && <div className="alert alert-success mt-3 text-center">
                <p className="mb-0">{fields[0].success_text}</p>
            </div>}
            
            <Form name={fields[0].formname} id="subscribe-form" noValidate validated={validated} onSubmit={handleSubmit}>
                <input type="hidden" name="form_name" value={fields[0].formname} />
                <input type="hidden" name="form_type" value={fields[0].form_type} />
                <input type="hidden" name="to_email_id" value={to_email_id} />
                <input type="hidden" name="bot-field" />
                <Row>
                {fields.map((field, index) => {
                    if ("input" === field.element ) {
                        return (
                            <InputField
                                key={index}
                                name={field.name}
                                grpmd={field.grpmd}
                                ref={field.ref}
                                type={(field.type === "date" || field.type === "time") ? "text" : field.type}
                                fieldClass={field.class}
                                label={field.label}
                                labelClass={field.labelClass}
                                placeholder={field.placeholder}
                                required={field.required}
                                pattern={field.patternchk}
                                handlechange={handlechange}
                                min={field.type == "date" ? moment().format("YYYY-MM-DD") : ""}
                                onFocus={(e) => {
                                    if(field.type === "date" || field.type === "time") {
                                        e.target.type = field.type
                                    }
                                }}
                            />
                        );
                    }
                    if ("select" === field.element) {
                        return (
                            <SelectField
                                key={index}
                                name={field.name}
                                grpmd={field.grpmd}
                                label={field.label}
                                ref={field.ref}
                                required={field.required}
                                fieldClass={field.class}
                                placeholder={field.placeholder}
                                values={field.values}
                                handlechange={handlechange}
                                componentprops={props}
                            />
                        );
                    }
                    if ("textarea" === field.element) {
                        return (
                            <TextAreaField
                                key={index}
                                name={field.name}
                                grpmd={field.grpmd}
                                ref={field.ref}
                                rows={field.rows}
                                fieldClass={field.class}
                                labelClass={field.labelClass}
                                placeholder={field.placeholder}
                                required={field.required}
                                handlechange={handlechange}
                            />
                        );
                    }
                    if ("checkbox" === field.element) {
                        return (
                            <CheckboxField
                                key={index}
                                name={field.name}
                                ref={field.ref}
                                value={field.value}
                                fieldClass={field.class}
                                placeholder={field.placeholder}
                                required={field.required}
                                handlechange={handlechange}
                            />
                        );
                    }
                    if ("html" === field.element) {
                        return (
                            <HtmlBox
                                key={index}
                                text={field.text}
                                fieldClass={field.class}
                            />
                        );
                    }
                    if ("captcha" === field.element && showCapcha) {
                        return (
                            <ReCaptchaBox
                                key={index}
                                fieldClass={field.class}
                                captRef={field.captchaRef}
                                handleonVerify={handleonVerify}
                            />
                        );
                    }
                    if ("button" === field.element) {
                        return (
                            <ButtonField
                                key={index}
                                name={field.name}
                                fieldClass={field.class}
                                type={field.type}
                                value={field.value}
                                grpmd={field.grpmd}
                            />
                                
                        );
                    }
                })
                }
                </Row>
            </Form>
        </div>
    );
}


const NewsSubscription = (props) => (
    <MySimpleForm  {...props}/>
)

export default NewsSubscription;