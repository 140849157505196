import React from "react";
import baloon from "../../images/baloon.png"

const StickyCta = () =>{
  return(
    <div className="sticky-section">
        <a href="/contact/property-valuation/" className="btn valuation-btn d-md-none">BOOK A VALUATION</a>
        {/* <a href={`tel: 020 7370 4000`} className="btn  d-md-none">
        CHAT NOW <img src={baloon} alt="baloon-icon" className="baloon-icon"/></a> */}
    </div>
  )
}
export default StickyCta